const dataLayer = window.dataLayer || [];
const data = window.data || [];
//https://bitbucket.org/adviso/generic-sis-lassonde/

//page load
dataLayer.push({
  event: 'PV',
  page: {
    content_group: data.content_group,
    page_language: data.language,
    page_title: document.title,
  },
  user: {
    user_industry: null, // Si non disponible, utiliser la valeur null.
    user_region: null, // Si non disponible, utiliser la valeur null.
  },
});

let formData = {};
//First step : store submited data
document.addEventListener('submit', function (event) {
  //using jQuery here, cause 'm a lazy boi
  jQuery.each(jQuery(event.target).serializeArray(), function () {
    formData[this.name] = this.value;
  });
});

//5.4 Quand un utilisateur commence à remplir un formulaire.
jQuery(document).on('focusin.contest', 'form', function () {
  jQuery(document).off('focusin.contest');
  dataLayer.push({
    event: 'userAction',
    interaction: {
      event_name: 'generate_lead',
      lead_step: 'start', // Utiliser 'start' si l'utilisateur commence à remplir l'un des champs et 'submit' quand le formulaire est soumis avec succès.
      lead_type: 'contest form',
    },
    user: {
      user_industry: null, // Si non disponible, utiliser la valeur null.
      user_region: null, // Si non disponible, utiliser la valeur null.
    },
  });
});

//5.3 UCS003 Quand un utilisateur commence son inscription à un concours et s'y inscrit avec succès (userAction)
jQuery(document).on('gform_confirmation_loaded', function () {
  dataLayer.push({
    event: 'userAction',
    interaction: {
      event_name: 'generate_lead',
      lead_step: 'submit', // Utiliser 'submit' si l'utilisateur commence à remplir l'un des champs et 'submit' quand le formulaire est soumis avec succès.
      lead_type: 'contest form',
    },
    user: {
      user_industry: null, // Si non disponible, utiliser la valeur null.
      user_region: null, // Si non disponible, utiliser la valeur null.
    },
  });
});

//click events
document.addEventListener('click', function (event) {
  //5.6 UCS006 - Lassonde - Clic vers les réseaux sociaux
  const socialMedia = event.target.closest('.social-media-button');
  if (socialMedia) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        interaction_location: 'header',
        click_type: 'social media click',
        link_text: socialMedia.title,
        link_url: socialMedia.href,
        social_media_name: socialMedia.querySelector('.sr-only').textContent, // Modifier en fonction du réseau social en question.
      },
    });
  }

  const socialMediaFooter = event.target.closest(
    '.instagram-section .social-media-button'
  );
  if (socialMediaFooter) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        interaction_location: 'footer',
        click_type: 'social media click',
        link_text: socialMediaFooter.title,
        link_url: socialMediaFooter.href,
        social_media_name:
          socialMediaFooter.querySelector('.sr-only').textContent, // Modifier en fonction du réseau social en question.
      },
    });
  }

  const socialMediaAtSunrype = event.target.closest(
    '.social-media-button-footer'
  );
  if (socialMediaAtSunrype) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        interaction_location: 'footer',
        click_type: 'social media click',
        link_text: socialMediaAtSunrype.textContent,
        link_url: socialMediaAtSunrype.href,
        social_media_name: 'Instagram',
      },
    });
  }

  const instagramFeedLink = event.target.closest('.sbi_photo');
  if (instagramFeedLink) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        interaction_location: 'footer',
        click_type: 'social media click',
        link_text:
          instagramFeedLink.querySelector('.sbi-screenreader').textContent,
        link_url: instagramFeedLink.href,
        social_media_name: 'Instagram',
      },
    });
  }

  //5.7 UCS007 - Lassonde - Clic vers un partenaire commercial
  const productSupplier = event.target.closest('.product-supplier');
  if (productSupplier) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'commercial link',
        link_url: productSupplier.href,
      },
    });
  }

  //5.8 UCS008 - Lassonde - Clic sur un produit
  const link = event.target.closest('.listing__item');
  if (link) {
    const url = link.href;
    const productLinkRegex =
      '\\/our-products\\/.*\\/.*\\/|\\/fr\\/nos-produits\\/.*\\/.*\\/';
    const isProduct = url.match(productLinkRegex);
    if (isProduct) {
      dataLayer.push({
        event: 'userAction',
        interaction: {
          event_name: 'click',
          click_type: 'product click',
          link_text: link.textContent.trim(),
          link_url: url,
        },
        product: {
          product_category: link.dataset.productCategory,
          product_name: link.dataset.productName,
          product_format: link.dataset.productSize,
        },
      });
    }
  }

  //5.9 UCS009 - Lassonde - Clic sur un CTA
  const ctaBtn = event.target.closest('.cta-btn, .suppliers-popup-btn');
  if (ctaBtn) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'button click',
        link_text: ctaBtn.textContent.trim(), // Voir le tableau 'CTA Buttons' pour la sélection des boutons à intégrer dans ce Use Case.
        link_url: ctaBtn.href,
      },
    });
  }

  //Clic d'un lien de menu
  const menuLink = event.target.closest('.nav-item__link');
  if (menuLink) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'button click',
        link_text: menuLink.textContent.trim(), // Voir le tableau 'CTA Buttons' pour la sélection des boutons à intégrer dans ce Use Case.
        link_url: menuLink.href,
      },
    });
  }

  //5.10 UCS010 - Lassonde - Lecture d'une vidéo
  //pour le moment toujours dans GTM, vu la complexité de mise en oeuvre

  //5.18 UCS018 - Lassonde - Clic vers le site Carriere
  const carreerLink = event.target.closest(
    '.footer-4__subnav__0 .nav-item__link, .bas-de-page-4__subnav__0 .nav-item__link'
  );
  if (carreerLink) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'career link',
        interaction_location: 'footer',
        link_url: carreerLink.href,
      },
    });
  }
});
